/* eslint-disable react/jsx-pascal-case */
import React from "react"
import { useTranslation } from "react-i18next"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const NotFound = () => {
  const { t } = useTranslation("common")
  return (
    <Layout>
      <SEO title={t("ERROR.NOT_FOUND")}></SEO>
      <h1>{t("ERROR.NOT_FOUND")}</h1>
      <StaticImage
        title={t("ERROR.NOT_FOUND")}
        alt={t("ERROR.NOT_FOUND")}
        src="404.png"
      />
    </Layout>
  )
}

export default NotFound

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
